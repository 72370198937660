import { whenever } from "@vueuse/core";

export const useModalStore = defineStore("modal", {
  state: () => ({
    /** @type {buffer} */
    buffer: [],
    updateTimeout: null,
  }),

  getters: {
    GET_ALL: (state) => {
      return state.buffer;
    },
  },

  actions: {
    /** @returns {Promise<resolve>} */
    SHOW_MODAL({ component = null, props = {} }) {
      return new Promise((resolve) => {
        const id = new Date().getTime();

        this.buffer.push({
          component,
          props,
          id,
          resolve: {
            resolved: false,
            success: false,
            reject: false,
            skip: false,
          },
        });

        whenever(
          () => this.buffer.find((modal) => modal.id === id)?.resolve.resolved,
          () => {
            resolve(
              this.buffer.find((modal) => modal.id === id)?.resolve ?? /** @type {resolve} */ {
                resolved: true,
                success: false,
                reject: false,
                skip: true,
              }
            );

            this.buffer.splice(0, 1);
          }
        );
      });
    },

    RESOLVE_MODAL(success = false, reject = false, skip = false) {
      this.buffer[0].resolve = { resolved: true, success, reject, skip };
    },
  },
});

/**
 * @typedef {Object} button
 * @property {string} text
 * @property {string} body
 * @property {string} class
 * @property {function} action
 * @property {String} component
 *
 * @typedef {modal[]} buffer
 *
 * @typedef {Object} modal
 * @property {string} text
 * @property {string} title
 * @property {any} component
 * @property {string} body
 * @property {button[]} buttons
 * @property {number} id
 * @property {resolve} resolve
 *
 *
 * @typedef {Object} resolve
 * @property {boolean} resolved - if the modal has been resolved
 * @property {boolean} success - if the modal has been resolved with success
 * @property {boolean} reject - if the modal has been resolved with reject
 * @property {boolean} skip - if the modal has been resolved with skip
 */
